import store from '@/store'
import Player from './Player'

class PlayerManager {
  constructor() {
    this.players = []
    this.playerMap = {}
    this._selfIdx = -1
  }

  get self() {
    if (this._selfIdx > -1) return this.players[this._selfIdx]
    return null
  }

  load(players) {
    players.forEach(data => {
      const player = new Player(data)
      this.players.push(player)

      // Set id lookup map for faster updates
      this.playerMap[player.id] = this.players.length - 1

      // Set index if this user is current user
      if (player.isMe) {
        this._selfIdx = this.players.length - 1
      }
    })
  }

  addPlayer(data) {
    if (this.getPlayerById(data[0])) {
      // Skip as user has already been added
      return
    }

    const player = new Player(data)
    this.players.push(player)

    // Set id lookup map for faster updates
    this.playerMap[player.id] = this.players.length - 1

    // Set index if this user is current user
    if (player.isMe) {
      this._selfIdx = this.players.length - 1
    }
  }

  removePlayer(data) {
    const idx = this.getPlayerIdxById(data.id)

    if (idx === -1) {
      // Player not found
      return
    }

    this.players.splice(idx, 1)

    // Recalculate player map
    this.playerMap = this.players.reduce((acc, cur, idx) => {
      acc[cur.id] = idx
      return acc
    }, {})

    this._selfIdx = this.players.findIndex(player => player.isMe)
  }

  getPlayerById(id) {
    const idx = this.playerMap[id]
    return this.players[idx]
  }

  getPlayerIdxById(id) {
    return this.playerMap[id]
  }

  getPlayers() {
    return this.players
  }

  updatePlayers(players) {
    players.forEach(data => {
      const player = this.getPlayerById(data[0])
      if (player) {
        player.update(data)
      }
    })
  }
}

export default PlayerManager