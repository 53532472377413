<template>
  <section aria-labelledby="next-game-title" class="bg-gray-50 overflow-hidden rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h2 class="text-base font-medium text-gray-900" id="next-game-title">Starting in</h2>
      <div class="mt-2 flex items-center space-x-3">
        <h2 class="text-4xl font-extrabold tracking-tight md:text-5xl">
          <vue-countdown :time="timeRemaining" v-slot="{ days, hours, minutes, seconds }">
            <template v-if="days">{{ days }}d {{ hours }}h {{ minutes }}m</template>
            <template v-else>
              {{ hours > 9 ? hours : `0${hours}` }}:{{ minutes > 9 ? minutes : `0${minutes}` }}:{{ seconds > 9 ? seconds : `0${seconds}` }}
            </template>
          </vue-countdown>
        </h2>
      </div>
      <div v-if="joined" class="mt-6">
        <div class="rounded-md bg-green-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-green-800">
                You've joined this game
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-6 space-x-3">
        <DBtn v-if="id" color="purple" primary lg @click="join" :loading="joinLoading">
          Join round
        </DBtn>
        <DBtn v-else color="purple" secondary lg @click="login">
          Sign in to play
        </DBtn>
      </div>
    </div>
  </section>

  <AddProfileModal v-model="showModal" @saved="join" />
</template>

<script setup>
import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import { useQuery, useResult } from '@vue/apollo-composable'
import { ownProfile } from '@/gql/auth'
import { CheckCircleIcon } from '@heroicons/vue/outline'
import { DBtn, DLink } from '@ui'

import AddProfileModal from './AddProfileModal.vue'

// Calculate the time remaining left on the game
const pitboss = inject('pitboss')
const game = inject('game')
const timeRemaining = computed(() => {
  return new Date(game.value.startAt).getTime() - Date.now()
})

// Load profile so we can check if user needs to set display name
const store = useStore()
const id = computed(() => store.getters['auth/id'])
const { result, loading, onResult: onProfile } = useQuery(ownProfile, () => ({ id: id.value }))
const hasName = useResult(result, null, ({ profile }) => !!profile.displayName)
const showModal = ref(false)

// Actually join the game
const joinLoading = ref(false)
const join = () => {
  if (!hasName.value) {
    showModal.value = true
    return
  }
  joinLoading.value = true
  pitboss.join(game.value.id)
}

// Check if user has joined
const joined = computed(() => store.getters['flappy/joined'])

const login = () => {
  store.dispatch('auth/loginWithMetamask')
}

// Automatically join the game on return if the user joined previously
onProfile(() => {
  if (joined.value) join()
})
</script>