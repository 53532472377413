<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <form @submit="save">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                  <IdentificationIcon class="h-6 w-6 text-purple-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    Set a display name
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      You need to set a public display name to join games. It must be unique, but can contain special characters and spaces.
                    </p>
                    <div class="mt-4">
                      <input type="text" name="name" v-model="displayName" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Satoshi Nakamoto" required />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex sm:space-x-3">
                <DBtn type="submit" color="purple" :loading="loading" primary>
                  Save
                </DBtn>
                <DBtn @click="open = false">
                  Cancel
                </DBtn>
              </div>
            </form>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { useMutation } from '@vue/apollo-composable'
import { updateName } from '@/gql/auth'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { IdentificationIcon } from '@heroicons/vue/outline'
import { DBtn } from '@ui'

const props = defineProps({ modelValue: Boolean })
const emit = defineEmits(['update:modelValue', 'saved'])
const open = computed({
  get: () => props.modelValue,
  set: val => emit('update:modelValue', val)
})

const store = useStore()
const id = store.getters['auth/id']
const displayName = ref('')

const { mutate, loading } = useMutation(updateName, () => ({
  variables: {
    id,
    displayName: displayName.value,
  },
}))

const save = async (e) => {
  e.preventDefault()
  try {
    await mutate()
    notify({ type: 'success', text: 'Profile saved' })
    emit('saved', displayName)
    open.value = false
  } catch (err) {
    if (err.message.includes('profiles_displayName_key')) {
      notify({ type: 'error', title: 'Invalid name', text: 'Already used by another account' })
    } else {
      notify({ type: 'error', title: 'Unexpected error', text: err.message })
    }
  }
}
</script>