import store from '@/store'
import Const from './Constants'
import Enums from './Enums'

const {
  SNEK_WIDTH,
  SNEK_HEIGHT,
  SPRITE_SNEK_HEIGHT,
  SPRITE_SNEK_WIDTH,
  ANIMATION_DURATION,
  ANIMATION_FRAME_NUMBER
} = Const

class Player {
  constructor(data) {
    const [id, posX, posY, rotation, score, state, displayName, avatarImg, color, userId] = data
    this._userId = userId
    this._raw = {
      id,
      posX,
      posY,
      rotation,
      score,
      state,
      displayName,
      avatarImg,
      color,
    }
    
    // Track which user is self to draw
    this.isMe = userId === store.getters['auth/id']
  }

  get id() {
    return this._raw.id
  }

  get userId() {
    return this._userId
  }

  get raw() {
    return this._raw
  }

  get score() {
    return this._raw.score
  }

  get isAlive() {
    return ['PLAYING', 'WAITING'].includes(this._raw.state)
  }

  draw(ctx, time, spriteList, gameState) {
    // TODO: handle conditionals on when we shouldnt draw the player

    // Save context
    ctx.save()

    if (this.isMe && [Enums.GameState.Pending, Enums.GameState.StartingSoon].includes(gameState)) {
      ctx.font = '25px mini_pixel'
      ctx.fillStyle = '#ffffff'
      const pos = this.raw.posX + (SNEK_WIDTH / 2) - (ctx.measureText('You').width / 2)
      ctx.fillText('You', pos, this.raw.posY - 20)
    }

    // If it's an opponent draw name and opacity
    if (!this.isMe) {
      ctx.globalAlpha = 0.6
      ctx.font = '25px mini_pixel'
      ctx.fillStyle = '#ffffff'

      const pos = this.raw.posX + (SNEK_WIDTH / 2) - (ctx.measureText(this.raw.displayName).width / 2)
      ctx.fillText(this.raw.displayName, pos, this.raw.posY - 20)
    }

    // Move to the center of the players snek
    ctx.translate(this.raw.posX + SNEK_WIDTH / 2, this.raw.posY + SNEK_HEIGHT / 2)
    ctx.rotate(this.raw.rotation * Math.PI / 180)

    if (this.raw.state === Enums.Player.Waiting) {
      // If waiting draw static
      ctx.drawImage(spriteList[this.raw.color], 0, 0, SPRITE_SNEK_WIDTH, SPRITE_SNEK_HEIGHT, SNEK_WIDTH / -2, SNEK_HEIGHT / -2, SNEK_WIDTH, SNEK_HEIGHT)
    } else {
      // Animate
      const frame = Math.round(time / ANIMATION_DURATION) % ANIMATION_FRAME_NUMBER
      ctx.drawImage(spriteList[this.raw.color], frame * SPRITE_SNEK_WIDTH, 0, SPRITE_SNEK_WIDTH, SPRITE_SNEK_HEIGHT, SNEK_WIDTH / -2, SNEK_HEIGHT / -2, SNEK_WIDTH, SNEK_HEIGHT)
    }

    // Restore canvas state
    ctx.restore()
  }

  update(data) {
    const [, posX, posY, rotation, score, state] = data
    this._raw = {
      ...this._raw,
      posX,
      posY,
      rotation,
      score,
      state,
    }
  }
}

export default Player