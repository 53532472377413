export default {
  // Game
  SCREEN_WIDTH:             900,
  SCREEN_HEIGHT:            768,
  FLOOR_POS_Y:              672,
  LEVEL_SPEED:              0.3,
  TIME_BETWEEN_GAMES:       5000,

  // Player
  SNEK_WIDTH:               216 / 6,
  SNEK_HEIGHT:              202 / 6,
  MAX_SNEKS_IN_A_ROW:       3,
  START_SNEK_POS_X:         100,
  SPACE_BETWEEN_SNEKS_X:    120,
  START_SNEK_POS_Y:         100,
  SPACE_BETWEEN_SNEKS_Y:    100,
  GRAVITY_SPEED:            0.04,
  JUMP_SPEED:               -0.6,
  MAX_ROTATION:             20,
  MIN_ROTATION:             35,
  ROTATION_SPEED:           8,

  // Pipe
  PIPE_WIDTH:               100,
  DISTANCE_BETWEEN_PIPES:   380,
  MIN_PIPE_HEIGHT:          60,
  MAX_PIPE_HEIGHT:          630,
  HEIGHT_BETWEEN_PIPES:     175,

  // Sprites
  SPRITE_SNEK_HEIGHT:       216,
  SPRITE_SNEK_WIDTH:        202,
  ANIMATION_DURATION:       150,
  ANIMATION_FRAME_NUMBER:   8,
  SPRITE_PIPE_HEIGHT:       768,
  SPRITE_PIPE_WIDTH:        148,
  STATUS_POS_Y:             50,
  STATUS_SHADOW_OFFSET:     2,
  COUNTDOWN_POS_Y:          125,
  COUNTDOWN_SHADOW_OFFSET:  3,
  DAY_TRANSITION_DURATION:  2000,
}