export default {
  GameState: {
    Pending: 'PENDING',
    StartingSoon: 'STARTING_SOON',
    Active: 'ACTIVE',
    Ended: 'ENDED',
  },
  Player: {
    Waiting: 'WAITING',
    Playing: 'PLAYING',
    Dead: 'DEAD',
  },
}