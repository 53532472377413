import gql from 'graphql-tag'

export const upcomingGames = gql`query upcomingGames {
  games(where: { status: { _in:["PENDING", "STARTING_SOON"] } }, order_by: { type: desc, startAt: asc }, limit: 1) {
    id
    type
    status
    startAt
  }
}`

export const nextGame = gql`query nextGame ($type: game_type) {
  games(where: { type: { _eq: $type }, status: { _in: ["PENDING", "STARTING_SOON"] } }, order_by: { startAt: asc }, limit: 1) {
    id
    type
    status
    startAt
    endedAt
    players(limit: 5, order_by: { position: desc_nulls_last, createdAt: desc }) {
      position
      winner
      profile {
        id
        displayName
        avatarImg
      }
    }
    prizes {
      id
      title
      subtitle
      avatarImg
    }
    stats: players_aggregate {
      aggregate {
        count
      }
    }
  }
}`

export const listLeaderboard = gql`query listLeaderboard ($type: game_type) {
  leaderboard: gameleaderboard(where: { gameType: { _eq: $type } }, order_by: { score: desc }, limit: 5) {
    id
    score
    updatedAt
    user {
      id
      displayName
      avatarImg
    }
  }
}`