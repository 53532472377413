<template>
  <canvas ref="canvas" width="900" height="768" class="max-w-full tracking-widest" />
</template>

<script setup>
import { inject, ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

const gameEngine = inject('gameEngine')
const canvas = ref(null)

onMounted(() => {
  gameEngine.initCanvas(canvas.value)
})
</script>

<style scoped>
@font-face {
  font-family: 'mini_pixel';
  src: url('/games/flappy/mini_pixel-7-webfont.eot');
  src: url('/games/flappy/mini_pixel-7-webfont.eot?#iefix') format('embedded-opentype'),
       url('/games/flappy/mini_pixel-7-webfont.woff') format('woff'),
       url('/games/flappy/mini_pixel-7-webfont.ttf') format('truetype'),
       url('/games/flappy/mini_pixel-7-webfont.svg#mini_pixel-7regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-minipixel {
  font-family: 'mini_pixel';
}
</style> 