/* eslint-disable no-case-declarations */
import { notify } from '@kyvg/vue3-notification'
import { useBreakpoint } from '@ui'
import store from '@/store'
import Enums from './Enums'
import CanvasPainter from './CanvasPainter'
import PlayerManager from './PlayerManager'

const breakpoint = useBreakpoint()
const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame

class GameEngine {
  constructor(pitboss) {
    this.state = Enums.GameState.Pending
    this.practiceMode = false
    this.nextRound = null

    this.players = new PlayerManager()
    this.pipes = []
    this._isNight = false
    this._lastTime = 0
    this._ws = pitboss._ws
    this._elem = null
    this.canvas = null

    // Listen for keypress and tap on elem
    document.addEventListener('keydown', this.onKeydown.bind(this))

    // Listen for events from websocket
    this._ws.addEventListener('message', this.onMessage.bind(this))
    this._ws.addEventListener('close', this.onFailure.bind(this))
    this._ws.addEventListener('error', this.onFailure.bind(this))

    this._lastMessage = Date.now()
  }

  async initCanvas(elem) {
    this._elem = elem
    this._elem.addEventListener('touchstart', this.onTouch.bind(this))

    this.canvas = new CanvasPainter(elem)
    await this.canvas.loadResources()
    
    this.draw(0, 0)
  }

  draw(curTime, elapsedTime) {
    // Canvas hasn't been loaded yet
    if (!this.canvas) return

    if (this.state === Enums.GameState.Active && this.players.self) {
      if (this.players.self.score >= 15) this._isNight = true
    }

    const gameState = {
      state: this.state,
      practiceMode: this.practiceMode,
      nextRound: this.nextRound,
      isNight: this._isNight
    }

    this.canvas.draw(curTime, elapsedTime, this.players, this.pipes, gameState)
  }

  gameLoop() {
    const curTime = Date.now()
    let elapsedTime = curTime - this._lastTime

    this.draw(curTime, elapsedTime)
    this._lastTime = curTime

    if (this.state === Enums.GameState.Active) {
      requestAnimationFrame(this.gameLoop.bind(this))
    } else {
      console.log(`status = ${this.state}: END gameLoop`)
    }
  }

  lobbyLoop() {
    this.draw(Date.now(), 0)

    if ([Enums.GameState.Pending, Enums.GameState.StartingSoon].includes(this.state)) {
      requestAnimationFrame(this.lobbyLoop.bind(this))
    } else {
      console.log(`status = ${this.state}: END lobbyLoop`)
    }
  }

  updateGameState(data) {
    const [id, status, startAt, practiceMode, nextRound] = data
    this.state = status
    this.practiceMode = practiceMode
    this.nextRound = nextRound

    switch (this.state) {
      case Enums.GameState.Pending:
        console.log(`status = ${this.state}: CALL lobbyLoop`)
        this.lobbyLoop()
        break

      case Enums.GameState.StartingSoon:
        console.log(`status = ${this.state}: CALL lobbyLoop`)
        if (!practiceMode) {
          notify({ type: 'warn', title: 'Get ready', text: 'Live game starting soon...' })
        }
        this.lobbyLoop()
        break

      case Enums.GameState.Active:
        console.log(`status = ${this.state}: CALL gameLoop`)
        this.gameLoop()
        break

      case Enums.GameState.Ended:
        console.log(`status = ${this.state}: CALL lobbyLoop`)
        this.lobbyLoop()
        break
    }
  }

  onMessage([type, data, ts]) {
    switch (type) {
      case 'game:stats':
        // Update on game stats
        console.log(data)
        store.dispatch('flappy/setStats', data)
        break

      case 'game:joined':
        // Joined game confirmation
        store.dispatch('flappy/joined')
        break

      case 'game:left':
        // Left game confirmation
        store.dispatch('flappy/left')
        break

      case 'game:playerlist':
        // Receive initial list of players
        this.players.load(data)
        store.dispatch('flappy/loadPlayers', data)
        break

      case 'game:player:joined':
        // New player joined the game
        console.log(`player:joined - ${data[0]}`)
        this.players.addPlayer(data)
        store.dispatch('flappy/addPlayer', data)
        break

      case 'game:player:eliminated': {
        // Player was eliminated
        console.log(`player:eliminated - ${data[0]}, ${data[1]}, ${data[2]}`)
        this.notifyElimination(data[0], data[1])
        // this.players.removePlayer(data.player)
        break
      }

      case 'game:player:disconnected':
        // Player left the game due to an error
        console.log(`player:disconnected - ${data[0]}`)
        this.players.removePlayer(data)
        break

      case 'game:state':
        // Game state has been changed
        this.updateGameState(data)
        store.dispatch('flappy/setGameState', data)
        break

      case 'game:loop':
        // Update state of all players/pipes
        this.players.updatePlayers(data[0])
        this.pipes = data[1].map(data => ({
          id: data[0],
          posX: data[1],
          posY: data[2],
        }))
        break

      case 'game:winner': {
        // Game winner announced
        console.log(`game:winner - ${data[0]}`)
        this.notifyWinner(data[0])
        break
      }

      case 'error':
        // Error
        break
    }

    this._lastMessage = Date.now()
  }

  onFailure(event) {
    console.log('something broke')
    console.log(event)
  }

  sendCmd(cmd) {
    if (this.state !== Enums.GameState.Active) return
    this._ws.send(['game:cmd', cmd])
  }

  onKeydown(event) {
    if (event.keyCode === 32) {
      event.preventDefault()
      this.sendCmd('jump')
    }
  }

  onTouch() {
    this.sendCmd('jump')
  }

  notifyElimination(id, rank) {
    const player = this.players.getPlayerById(id)
    store.dispatch('flappy/eliminatePlayer', [player.raw, rank])

    // Don't show notifications on mobile (covers game)
    if (breakpoint.mdAndDown) return

    if (player.isMe && rank !== 1) {
      notify({
        title: `You were eliminated`,
        text: `${rank - 1} players remain`,
        data: {
          avatarImg: player.raw.avatarImg,
        }
      })
    }
  }

  notifyWinner(id) {
    const player = this.players.getPlayerById(id)
    const label = player.isMe ? 'You' : player.raw.displayName
    const data = { avatarImg: player.raw.avatarImg }

    if (this.practiceMode) {
      notify({
        title: `${label} won this practice round`,
        text: `Survived ${player.raw.score} pipes`,
        data,
      })
    } else {
      notify({
        title: `${label} won!`,
        text: `Congratulate them on Discord`,
        data,
      })
    }

  }
}

export default GameEngine