<template>
  <section v-if="loading" aria-labelledby="quick-links-title">
    <div class="rounded-lg bg-white overflow-hidden shadow p-6">
      <div class="md:flex md:items-center md:justify-between">
        <h2 class="text-xl font-bold text-gray-900 sm:text-2xl">Flappy Snek</h2>
      </div>
      
      <div class="mt-6 block w-full border-2 border-gray-300 border-dashed rounded-lg p-16 text-center focus:outline-none">
        <RefreshIcon class="w-8 h-8 text-gray-500 animate-spin mx-auto" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          Loading game...
        </span>
      </div>
    </div>
  </section>
  <div v-else class="max-w-6xl mx-auto">
    <GameCard title="Flappy Snek">
      <FlappySnek v-if="showGame" />
      <div v-else class="block w-full border-2 border-gray-300 border-dashed rounded-lg p-16 text-center focus:outline-none">
        <SnekIcon class="w-10 h-10 text-gray-500 mx-auto" />
        <span class="mt-4 block text-sm font-medium text-gray-900">
          Join the round to start playing
        </span>
      </div>
    </GameCard>

    <section class="px-4 py-5 sm:p-6">
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <CountdownCard />
        <LeaderboardCard :type="game.type" />
      </div>
    </section>
  </div>
</template>

<script setup>
import { inject, provide, ref, computed } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { nextGame } from '@/gql/games'

import SidebarFlappy from './SidebarFlappy.vue'
import GameCard from '@/components/roof/games/GameCard.vue'
import StatsCard from '@/components/roof/games/flappy/StatsCard.vue'
import DHHInfoCard from '@/components/roof/games/DHHInfoCard.vue'
import MailingListCard from '@/components/roof/games/MailingListCard.vue'

import GameEngine from '@/components/roof/games/flappy/lib/GameEngine'
import FlappySnek from '@/components/roof/games/flappy/FlappySnek.vue'

import CountdownCard from '@/components/roof/games/CountdownCard.vue'
import PrizesCard from '@/components/roof/games/PrizesCard.vue'
import EventsCard from '@/components/roof/games/EventsCard.vue'
import SneksPromoCard from '@/components/roof/games/SneksPromoCard.vue'
import LeaderboardCard from '@/components/roof/games/LeaderboardCard.vue'


const { result, loading } = useQuery(nextGame, { type: 'FLAPPY' })
const game = useResult(result, null, data => data.games[0])

provide('game', game)

import { LockClosedIcon, RefreshIcon } from '@heroicons/vue/outline'
import { useBreakpoint, SnekIcon } from '@ui'

const breakpoint = useBreakpoint()

const store = useStore()
const pitboss = inject('pitboss')

const showGame = computed(() => store.getters['flappy/joined'])

const gameEngine = new GameEngine(pitboss)
provide('gameEngine', gameEngine)
</script>