import Const from './Constants'

export default [
  {
    daySrc: null,
    nightSrc: '/games/flappy/night.png',
    width: 500,
    height: 768,
    posY: 0,
    speed: Const.LEVEL_SPEED / 4,
  },
  {
    daySrc: '/games/flappy/clouds.png',
    nightSrc: '/games/flappy/night-clouds.png',
    width: 300,
    height: 256,
    posY: 416,
    speed: Const.LEVEL_SPEED / 3,
  },
  {
    daySrc: '/games/flappy/city.png',
    nightSrc: '/games/flappy/night-city.png',
    width: 300,
    height: 256,
    posY: 416,
    speed: Const.LEVEL_SPEED / 2,
  },
  {
    daySrc: '/games/flappy/trees.png',
    nightSrc: '/games/flappy/night-trees.png',
    width: 300,
    height: 216,
    posY: 456,
    speed: Const.LEVEL_SPEED,
  },
]