import Const from './Constants'

// Every background image is an instance of ParallaxImg
class ParallaxImg {
  constructor(daySrc, nightSrc, config, screenWidth) {
    this.daySrc = daySrc
    this.nightSrc = nightSrc
    this.speed = config.speed
    this.posY = config.posY
    this.posX = 0
    this.width = config.width
    this.height = config.height
    this.maxW = screenWidth

    this._isNight = false
    this._isCalcOpacity = false
    this._nightOpacity = 0
    this._changeOpacityTime = 0
  }

  draw(ctx, time, isNight) {
    let drawPos;

    // Update background position
    drawPos = this.posX = (this.posX - Math.floor(time * this.speed)) % this.width

    // Calculate opacity
    this.calcOpacity(time, isNight)

    // While we don't completly fill the screen, draw a part of the bg
    while (drawPos < this.maxW) {
      // If it's not full night, draw day BG
      if (this.daySrc && this._nightOpacity !== 1) {
        ctx.drawImage(this.daySrc, drawPos, this.posY, this.width, this.height)
      }

      // If we are in a night cycle redraw the bg with the opaque night resource
      if (this.nightSrc && this._isNight) {
        // If it's not full night, save context and apply opacity on the night picture
        if (this._nightOpacity !== 1) {
          ctx.save()
          ctx.globalAlpha = this._nightOpacity
        }

        ctx.drawImage(this.nightSrc, drawPos, this.posY, this.width, this.height)

        if (this._nightOpacity !== 1) ctx.restore()
      }

      drawPos += this.width
    }
  }

  resetToDayCycle() {
    this._isNight = false
    this._isCalcOpacity = false
    this._nightOpacity = 0
    this._changeOpacityTime = 0
  }

  calcOpacity(time, isNight) {
    // If there is a change between the previous cycle and now we have to smoothly recompute night opacity
    if (this._isNight != isNight) {
      this._isNight = isNight
      this._isCalcOpacity = true
      this._changeOpacityTime = 0
    }

    // If we are currently changing
    if (this._isCalcOpacity) {
      this._changeOpacityTime += time
      this._nightOpacity = this._changeOpacityTime / Const.DAY_TRANSITION_DURATION

      // Stop computing if transition is done
      if (this._changeOpacityTime  >= Const.DAY_TRANSITION_DURATION) {
        this._isCalcOpacity = false
        this._nightOpacity = this._isNight ? 1 : 0
        this._changeOpacityTime = 0
      }

      // If transitioning the other way then reverse
      if (!this._isNight) {
        this._nightOpacity = 1 - this._nightOpacity
      }
    }
  }
}

export default ParallaxImg