import ParallaxImg from './ParallaxImg'
import Backgrounds from './Backgrounds'
import Const from './Constants'
import Enums from './Enums'

const {
  STATUS_POS_Y,
  STATUS_SHADOW_OFFSET,
  COUNTDOWN_POS_Y,
  COUNTDOWN_SHADOW_OFFSET,
  PIPE_WIDTH,
  HEIGHT_BETWEEN_PIPES,
  SPRITE_PIPE_HEIGHT,
  SPRITE_PIPE_WIDTH,
  SCREEN_WIDTH,
  SCREEN_HEIGHT,
  LEVEL_SPEED,
} = Const

class CanvasPainter {
  constructor(elem) {
    this.ctx = elem.getContext('2d')
    this._ready = false
    this._imgGround = null
    this._imgPipe = null
    this._imgSneks = null
    this._imgBg = null
  }

  loadImg(src) {
    return new Promise((r, j) => {
      const img = new Image()
      img.onload = () => r(img)
      img.onerror = j
      img.src = src
    })
  }

  loadImgSet(...src) {
    return Promise.all(src.map(img => img ? this.loadImg(img) : null))
  }

  async loadResources() {
    // Load ground, pipe and sneks
    const [bg, ground, pipe, sneks] = await Promise.all([
      this.loadImg('/games/flappy/bg.png'),
      this.loadImg('/games/flappy/ground.png'),
      this.loadImg('/games/flappy/pipe.png'),
      this.loadImgSet('/games/flappy/snek.png', '/games/flappy/snek-cyan.png', '/games/flappy/snek-orange.png', '/games/flappy/snek-purple.png', '/games/flappy/snek-red.png')
    ])

    // Load background assets
    const backgrounds = await Promise.all(Backgrounds.map(async config => {
      const [day, night] = await this.loadImgSet(config.daySrc, config.nightSrc)
      return new ParallaxImg(day, night, config, SCREEN_WIDTH)
    }))

    this._imgGround = new ParallaxImg(ground, null, {
      width: 900,
      height: 96,
      posY: 672,
      speed: LEVEL_SPEED
    }, SCREEN_WIDTH)

    this._imgBg = bg
    this._imgPipe = pipe
    this._imgSneks = sneks
    this._imgParalax = backgrounds
    this._ready = true
  }

  draw(currentTime, elapsedTime, playerManager, pipes, gameState) {
    let startAt = Date.now()

    if (!this._ready) {
      console.log('Not ready to draw yet')
      return
    }

    const { state, practiceMode, nextRound, isNight } = gameState
    const players = playerManager.getPlayers()

    this.ctx.fillStyle = '#000000';
    this.ctx.fillRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);
    this.ctx.drawImage(this._imgBg, 0, 0, SCREEN_WIDTH, SCREEN_HEIGHT, 0, 0, SCREEN_WIDTH, SCREEN_HEIGHT)

    // Draw backgrounds
    for (let i = 0; i < this._imgParalax.length; i++) {
      this._imgParalax[i].draw(this.ctx, elapsedTime, isNight)
    }

    // Draw pipes
    for (let i = 0; i < pipes.length; i++) {
      this.drawPipe(pipes[i])
    }

    // Draw players
    for (let i = 0; i < players.length; i++) {
      players[i].draw(this.ctx, currentTime, this._imgSneks, state)
    }

    this.drawStatusBar(currentTime, playerManager.self, gameState)

    // Draw the ground, animated if we're playing
    const animateGround = state === Enums.GameState.Active
    this._imgGround.draw(this.ctx, animateGround ? currentTime : 0, isNight)

    let duration = Date.now() - startAt
  }

  drawPipe(pipe) {
    // Bottom pipe
    this.ctx.drawImage(this._imgPipe, 0, 0, SPRITE_PIPE_WIDTH, SPRITE_PIPE_HEIGHT, pipe.posX, pipe.posY - SPRITE_PIPE_HEIGHT, PIPE_WIDTH, SPRITE_PIPE_HEIGHT)
    // Top pipe
    this.ctx.drawImage(this._imgPipe, 0, 0, SPRITE_PIPE_WIDTH, SPRITE_PIPE_HEIGHT, pipe.posX, pipe.posY + HEIGHT_BETWEEN_PIPES, PIPE_WIDTH, SPRITE_PIPE_HEIGHT)
  }

  drawStatusBar(currentTime, player, gameState) {
    const { state, practiceMode, nextRound } = gameState

    if (player) {
      this.drawScore(player.score)
    }
    
    if (state === Enums.GameState.StartingSoon) {
      if (practiceMode) {
        this.drawPracticeCountdown(currentTime, nextRound)
      } else {
        this.drawRealCountdown(currentTime, nextRound)
      }
    }

    this.drawMode(practiceMode, player)
  }

  drawScore(score) {
    const posX = SCREEN_WIDTH - STATUS_POS_Y - this.ctx.measureText(score).width
    this.ctx.font = '60px mini_pixel';
    this.ctx.fillStyle = '#DB2777';
    this.ctx.fillText(score, posX + STATUS_SHADOW_OFFSET, STATUS_POS_Y + STATUS_SHADOW_OFFSET + 10);
    this.ctx.fillStyle = 'white';
    this.ctx.fillText(score, posX, STATUS_POS_Y + 10);
  }

  drawPracticeCountdown(currentTime, nextRound) {
    const time = Math.ceil((nextRound - currentTime) / 1000)
    const label = `Starting in ${time}s`

    this.ctx.font = '60px mini_pixel';
    this.ctx.fillStyle = '#DB2777';
    const posX = (SCREEN_WIDTH / 2) - (this.ctx.measureText(label).width / 2)
    this.ctx.fillText(label, posX + COUNTDOWN_SHADOW_OFFSET, COUNTDOWN_POS_Y + COUNTDOWN_SHADOW_OFFSET);
    this.ctx.fillStyle = 'white';
    this.ctx.fillText(label, posX, COUNTDOWN_POS_Y);  
  }

  drawRealCountdown(currentTime, nextRound) {
    const time = Math.ceil((nextRound - currentTime) / 1000)
    const label = 'Get ready...'

    this.ctx.font = '60px mini_pixel';
    this.ctx.fillStyle = '#DB2777';
    const labelPosX = (SCREEN_WIDTH / 2) - (this.ctx.measureText(label).width / 2)
    this.ctx.fillText(label, labelPosX + COUNTDOWN_SHADOW_OFFSET, COUNTDOWN_POS_Y + COUNTDOWN_SHADOW_OFFSET);
    this.ctx.fillStyle = 'white';
    this.ctx.fillText(label, labelPosX, COUNTDOWN_POS_Y);

    this.ctx.font = '120px mini_pixel';
    this.ctx.fillStyle = '#DB2777';
    const scorePosX = (SCREEN_WIDTH / 2) - (this.ctx.measureText(time).width / 2)
    this.ctx.fillText(time, scorePosX + COUNTDOWN_SHADOW_OFFSET, COUNTDOWN_POS_Y + COUNTDOWN_SHADOW_OFFSET + 100);
    this.ctx.fillStyle = 'white';
    this.ctx.fillText(time, scorePosX, COUNTDOWN_POS_Y + 100);
  }

  drawMode(practiceMode, player) {
    let label = practiceMode ? 'PRACTICE' : 'LIVE'
    if (!player || !player.isAlive) label = 'SPECTATING'
    const color = practiceMode ? 'white' : '#FCE7F3'
    const posX = STATUS_POS_Y

    this.ctx.font = '30px mini_pixel';
    this.ctx.fillStyle = '#DB2777';
    this.ctx.fillText(label, posX + STATUS_SHADOW_OFFSET, STATUS_POS_Y + STATUS_SHADOW_OFFSET);
    this.ctx.fillStyle = color;
    this.ctx.fillText(label, posX, STATUS_POS_Y);
  }
  
}

export default CanvasPainter